.header {
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  box-shadow:
    rgba(0, 0, 0, 0.01) 0px 1px 3px,
    rgba(0, 0, 0, 0.04) 0px 1px 2px;
}

.wrapperGroup {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.brandHolder {
  vertical-align: middle;
  cursor: pointer;
}

.brandSpacer {
  display: inline-block;
}

.brandLogoWide {
  height: 32px;
  pointer-events: none;
}

.brandLogo {
  width: 32px;
  pointer-events: none;
}

.brandName {
  display: inline-block;
  vertical-align: middle;
}
