.menuDropdown {
  min-width: 300px;
}

.unselectable {
  pointer-events: none;
}

.userName {
  font-weight: 500;
}
