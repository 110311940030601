.root {
  padding-top: 100px;
  padding-bottom: 80px;
  min-height: 80vh;
}

.image {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.title {
  text-align: center;
  font-weight: 300;
  font-size: 38px;

  /*noinspection CssInvalidAtRule*/
  @mixin smaller-than $mantine-breakpoint-sm {
    font-size: 32px;
  }
}


.description {
  max-width: 540px;
  font-weight: 300;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: var(--mantine-spacing-xl);
}
