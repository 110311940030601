.root {
  flex-shrink: 0;
  z-index: 100;
}

.navList {
  min-height: 100%;
}

.mainSection {
  height: unset;
  margin-top: 0 !important;
  background-color: var(--mj-color-body-background);
}

.scrollArea {
  margin-top: 0 !important;
  :global(.mantine-ScrollArea-scrollbar) {
    width: 10px !important;
  }
  :global(.mantine-ScrollArea-thumb) {
    z-index: 6;
  }
}

.scrollBar {
  width: 8px !important;
}

.scrollThumb {
  z-index: 6;

  &::before {
    min-width: 4px;
  }
}

.scrollViewport {
  > div {
    height: 100%;
    display: block !important;
    overflow-wrap: anywhere !important;
    overflow-x: hidden !important;
  }
}

.dragger {
  width: 5px;
  cursor: ew-resize;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
