.container {
  /* display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start !important;
  gap: 0;
  background-color: var(--mj-color-body-background);

  > :global(.mantine-Container-root) {
    flex-grow: 1;
    margin: 0;
    margin-inline: 0;
  }
}
