.expandedSection {
  position: fixed;
  overflow-x: hidden;
  flex-shrink: 0;
  box-shadow: var(--mantine-shadow-xs);
  border: calc(0.0625rem * var(--mantine-scale)) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important;

  border-radius: var(--mantine-radius-sm) !important;

  z-index: 100;

  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  bottom: 0;

  height: calc(
    100dvh - var(--app-shell-header-offset, 0px) -
      var(--app-shell-footer-offset, 0px) - 20px
  ) !important;

  &:not(.expanded) {
    width: 0;
    border: none !important;
  }

  &.expanded {
    right: 0;
    width: unset;
  }

  &.recorderOpened {
    top: 0;
    height: 100vh;
  }
}
