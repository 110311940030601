@layer base, components, utilities, mantine, mantine-contextmenu, mantine-datatable;

p {
  margin: 0;
}

.cm-tooltip.cm-tooltip.cm-tooltip {
  z-index: 999999;
  margin-top: 4px;
}

.cm-tooltip.cm-tooltip-autocomplete {
  font-size: var(--mantine-font-size-xs);
  font-family: var(--mantine-font-family-monospace);

  > ul > li {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.mantine-Drawer-root {
  z-index: 10000;
}

svg {
  display: unset;
}

.mantine-datatable-pagination {
  flex-direction: row-reverse !important;

  > .mantine-datatable-pagination-pages {
    flex-grow: 1;
  }

  > .mantine-datatable-pagination-text {
    flex-grow: 0;
    color: var(--mantine-color-dimmed);
  }
}
